import { ascii32 } from '../utils/ascii'

type FsarVersion = 2

/**
 * The header of a FSAR file.
 * 32-bit only.
 */
export class FsarHeader {
    /**
     * The number of entries in the FSAR file.
     */
    entries: number

    /**
     * The version of the FSAR file.
     */
    version: FsarVersion = 2

    /**
     * The start of data offset.
     */
    startOfData: number

    /**
     * Builds the binary representation of a FSAR header.
     */
    build(): ArrayBufferLike {
        const buffer = new ArrayBuffer(0x20)
        const view = new DataView(buffer)

        // Format: BE
        // 0x00: u32 magic => FSAR
        // 0x04: u32 version => this.version
        // 0x08: u32 start of data => FSAR_HEADER_SIZE + this.entries.length * FSAR_TOC_ENTRY_SIZE
        // 0x0C: u32 number of entries => this.entries.length
        // 0x10: u128 filler => 0x78..

        // Set the important values of the header
        view.setUint32(0x00, ascii32`FSAR`, false)
        view.setUint32(0x04, this.version, false)
        view.setUint32(0x08, this.startOfData, false)
        view.setUint32(0x0c, this.entries, false)

        // 0x10 .. 0x20: filler
        for (let i = 0x10; i < 0x20; i++) {
            view.setUint8(i, 0x78)
        }

        return buffer
    }
}
