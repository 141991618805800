import React from 'react'
import styles from './file.module.scss'

import { FsarBuilderEntry } from '../../fsar/builder/entry'
import { Card } from '../../components/card'
import { Icon } from '../../components/icon'
import {
    Path,
    RawPath,
    extractPath as rawPathToPath,
    buildRawPath,
} from '../../utils/path'
import { Stack } from '../../components/stack'
import { Row } from '../../components/row'
import { Text } from '../../components/text'

interface Props {
    file: FsarBuilderEntry
    onToggleCompression: (enabled) => void
    onRemove: () => void
    onUpdatePath?: (segments: string[]) => void
}

type FileType = 'image' | 'sound' | 'model' | 'text' | 'unknown'

function getFiletype(filename: string) {
    const split = filename.split('.')
    switch (split[split.length - 1]) {
        case 'img':
            return 'image'
        case 'cent':
        case 'xml':
            return 'text'

        default:
            return 'unknown'
    }
}

function extractPath(
    path: Path
): [path: string[], filename: string, fileType: FileType] {
    const pathSegments = path.length > 1 ? path.slice(0, path.length - 1) : []
    const filename = path[path.length - 1]
    const filetype = getFiletype(filename)

    return [pathSegments, filename, filetype]
}

export function File({
    file,
    onToggleCompression,
    onRemove,
    onUpdatePath,
}: Props) {
    const [path, filename, fileType] = extractPath(file.path)
    const [editMode, setEditMode] = React.useState(false)
    const [newPath, setNewPath] = React.useState(
        buildRawPath([...path, filename], '/')
    )

    function editName() {
        setEditMode(true)
    }

    function updatePath(path: RawPath) {
        const newPath = rawPathToPath(path)
        onUpdatePath?.(newPath)
    }

    // Input field that can execute updatePath on enter press, and revert back to the original if escape is pressed
    const editWidget = (
        <div>
            <input
                style={{ width: '100%' }}
                type="text"
                value={newPath}
                onChange={(e) => setNewPath(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        updatePath(e.target.value)
                        setEditMode(false)
                    } else if (e.key === 'Escape') {
                        updatePath(buildRawPath([...path, filename], '/'))
                        setEditMode(false)
                    }
                }}
                autoFocus
                onBlur={() => {
                    updatePath(buildRawPath([...path, filename], '/'))
                    setEditMode(false)
                }}
            />
        </div>
    )

    const viewWidget = (
        <Stack verticalAlign={'center'}>
            {path.length > 0 && (
                <Row gap={8}>
                    {path.map((segment, index) => (
                        <Text secondary extent={'small'}>
                            <Row gap={8}>
                                <span>{segment}</span>
                                {index !== path.length - 1 ? (
                                    <span className={styles.pathSeparator}>
                                        /
                                    </span>
                                ) : (
                                    ''
                                )}
                            </Row>
                        </Text>
                    ))}
                </Row>
            )}
            <div>
                <Text extent={'body'} weight={'semi-bold'}>
                    {filename}
                </Text>
                <Icon type={'pen'} size={'small'} onClick={editName} />
            </div>
        </Stack>
    )

    return (
        <div className={styles.container}>
            <Card>
                <Row
                    className={styles.leftCard}
                    verticalAlign={'center'}
                    gap={12}
                >
                    <div className={styles.handle} />
                    <div className={styles.text}>
                        {editMode ? editWidget : viewWidget}
                    </div>
                    <Row className={styles.actions} verticalAlign={'center'}>
                        <div
                            className={[
                                styles.compression,
                                file.flags?.compressed
                                    ? ''
                                    : styles.uncompressed,
                            ].join(' ')}
                            onClick={() =>
                                onToggleCompression(!file.flags?.compressed)
                            }
                        >
                            <Icon type="box" />
                        </div>
                    </Row>
                </Row>
            </Card>
            <Card>
                <div className={styles.removeButton} onClick={onRemove}>
                    <Icon type={'trash'} />
                </div>
            </Card>
        </div>
    )

    // return (
    //     <div>
    //         <div className={styles.cardMain}>
    //             <div className={styles.handle} />
    //             <div className={styles.text}>
    //                 {path.length > 0 && (
    //                     <div>
    //                         {path.map((segment, index) => (
    //                             <span className={styles.path}>
    //                                 {segment}
    //                                 {index !== path.length - 2 ? (
    //                                     <span className={styles.pathSeparator}>
    //                                         /
    //                                     </span>
    //                                 ) : (
    //                                     ''
    //                                 )}
    //                             </span>
    //                         ))}
    //                     </div>
    //                 )}
    //                 <div>{filename}</div>
    //             </div>
    //             <div>Todo: Compression</div>
    //         </div>
    //         <div className={styles.removeCard} onClick={onRemove}>
    //             Remove Icon
    //         </div>
    //     </div>
    // )
}
