import React from 'react'
import { Color, colors } from '../colors'
import { Wrench, Plus, Trash, Pencil, Cube, IconWeight } from 'phosphor-react'

export type IconType = 'wrench' | 'plus' | 'pen' | 'trash' | 'box'

type IconSize = 'small' | 'medium' | 'large'

interface IconProps {
    type: IconType
    size?: IconSize
    weight?: IconWeight
    color?: Color
    onClick?: () => void
}

const sizes: { [size in IconSize]: string } = {
    small: '16px',
    medium: '24px',
    large: '32px',
}

export function Icon({ onClick, type, size, color, weight }: IconProps) {
    const props = {
        size: sizes[size ?? 'medium'],
        color: colors[color ?? 'currentColor'],
        weight: weight || 'regular',
        onClick,
    }

    switch (type) {
        case 'wrench':
            return <Wrench {...props} />
        case 'plus':
            return <Plus {...props} />
        case 'pen':
            return <Pencil {...props} />
        case 'trash':
            return <Trash {...props} />
        case 'box':
            return <Cube {...props} />
    }

    return null
}
