import pako from 'pako'
import { Path } from '../../utils/path'

export type FsarBuilderEntryCompressionMethod = {
    mode: 'zlib'
    level: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | -1
}

export type FsarBuilderEntryFile =
    | {
          type: 'uint8array'
          data: Uint8Array
      }
    | {
          type: 'arraybuffer'
          data: ArrayBufferLike
      }
    | {
          type: 'file'
          data: File
      }

export type FsarBuilderEntry = {
    path: Path
    file: FsarBuilderEntryFile
    flags?: {
        compressed?: FsarBuilderEntryCompressionMethod
        offsetToNext?: number
    }
}

type FsarBuiltEntry = {
    data: Uint8Array
    size: {
        stored: number
        real: number
    }
}

async function getDataFromFile(
    file: FsarBuilderEntryFile
): Promise<Uint8Array> {
    switch (file.type) {
        case 'uint8array':
            return file.data
        case 'arraybuffer':
            return new Uint8Array(file.data)
        case 'file':
            return new Uint8Array(await file.data.arrayBuffer())
    }
}

function getFileSize(file: FsarBuilderEntryFile) {
    switch (file.type) {
        case 'uint8array':
        case 'arraybuffer':
            return file.data.byteLength
        case 'file':
            return file.data.size
        default:
            // @ts-expect-error
            throw new Error(`Unknown file type: ${file.type}`)
    }
}

export async function getCompressedFileBuffer(entry: FsarBuilderEntry) {
    if (!entry.flags?.compressed) {
        throw new Error(
            'Attempted to compress a file that does not wish to be compressed'
        )
    }

    const compressionMethod = entry.flags.compressed

    switch (compressionMethod.mode) {
        case 'zlib': {
            const data = await getDataFromFile(entry.file)
            return pako.deflate(data, {
                level: compressionMethod.level,
            })
        }
        default:
            throw new Error(
                `Unknown compression method: ${compressionMethod.mode}`
            )
    }
}

export async function buildEntry(
    entry: FsarBuilderEntry
): Promise<FsarBuiltEntry> {
    const data = entry.flags?.compressed
        ? await getCompressedFileBuffer(entry)
        : await getDataFromFile(entry.file)

    const size = {
        stored: data.byteLength,
        real: getFileSize(entry.file),
    }

    return {
        data,
        size,
    }
}
