import React, { useState } from 'react'
import { motion, Reorder } from 'framer-motion'
import { FsarBuilderEntry } from '../../fsar/builder/entry'
import { File } from './file'
import { Text, Description, Header } from '../../components/text'
import { Button } from '../../components/button'
import { Row } from '../../components/row'
import { extractPath, Separator } from '../../utils/path'
import { Stack } from '../../components/stack'
import { useI18n } from '../../i18n/store'
import * as styles from './index.module.scss'
import { build as buildFsar } from '../../fsar'

export function Builder() {
    const { __ } = useI18n()

    const [separator, setSeparator] = useState<Separator>('/')
    const [entries, setEntries] = useState<FsarBuilderEntry[]>([])
    const [state, setState] = useState<'designing' | 'building' | 'finished'>(
        'designing'
    )
    const numberFormatter = new Intl.NumberFormat()

    async function build() {
        setState('building')

        // Get write handle
        const file = await window.showSaveFilePicker({
            suggestedName: 'archive.far',
        })

        await buildFsar(file, entries, separator)

        setState('designing')
    }

    function handleReorder() {}

    async function addFile() {
        const files = await window.showOpenFilePicker({ multiple: true })

        let newEntries = []

        for (let file of files) {
            const actualFile = (await file.getFile()) as File

            const entry: FsarBuilderEntry = {
                path: extractPath(file.name),
                file: {
                    type: 'file',
                    data: actualFile,
                },
                // FIXME: Do we want compression on by default?
                // Schreibt's mal in die Kommentare!
                flags: {
                    compressed: {
                        mode: 'zlib',
                        level: 8,
                    },
                },
            }

            newEntries.push(entry)
        }
        setEntries([...entries, ...newEntries])
    }

    async function addDirectory() {
        const directory = await window.showDirectoryPicker({ multiple: true })
        const newEntries = await _addDirectory(directory, [])

        setEntries([...entries, ...newEntries])
    }

    async function _addDirectory(
        directory: FileSystemDirectoryHandle,
        path: string[]
    ): Promise<FsarBuilderEntry[]> {
        const newEntries: FsarBuilderEntry[] = []

        const indent = ' '.repeat(path.length * 2)
        console.log(indent + 'Adding directory:', directory.name)

        for await (let entry of directory.values()) {
            if (entry.kind === 'file') {
                const file = (await entry.getFile()) as File

                const builderEntry: FsarBuilderEntry = {
                    path: [...path, entry.name],
                    file: {
                        type: 'file',
                        data: file,
                    },
                    // FIXME: Do we want compression on by default?
                    // Schreibt's mal in die Kommentare!
                    flags: {
                        compressed: {
                            mode: 'zlib',
                            level: 8,
                        },
                    },
                }

                newEntries.push(builderEntry)
            }

            if (entry.kind === 'directory') {
                const newDirectory = await _addDirectory(entry, [
                    ...path,
                    entry.name,
                ])
                newEntries.push(...newDirectory)
            }
        }

        console.log(indent + 'Done adding directory:', directory.name)
        console.log(indent + 'Added', newEntries.length, 'entries')

        return newEntries
    }

    return (
        <Stack gap={32}>
            <Stack gap={12}>
                <Header>{__('builder.fsar.header')}</Header>
                <Description>
                    {__('builder.fsar.description')}
                    {/*Here you can add new files, rename, reorder, or compress files*/}
                    {/*as needed. <br />*/}
                    {/*You can also remove files using the trash can symbol next to it.*/}
                </Description>
            </Stack>
            <Stack
                stretch
                verticalAlign={'center'}
                horizontalAlign={'center'}
                gap={12}
            >
                <Row
                    stretch
                    verticalAlign={'center'}
                    horizontalAlign={'space-between'}
                >
                    <Text extent={'small'}>
                        {/* Todo: Move this over to I18n system */}
                        {numberFormatter.format(entries.length)} File
                        {entries.length !== 1 ? 's' : ''}
                    </Text>
                    <Row gap={8}>
                        <Button
                            onClick={() => {
                                setSeparator((separator) => {
                                    if (separator === '/') {
                                        return '\\'
                                    } else {
                                        return '/'
                                    }
                                })
                            }}
                        >
                            <Text extent={'small'}>
                                {__('builder.fsar.toggle.separator', separator)}
                            </Text>
                        </Button>
                        <Button onClick={build} icon={'wrench'}>
                            <Text extent={'small'}>
                                {__('builder.fsar.build')}
                            </Text>
                        </Button>
                    </Row>
                </Row>
                <Reorder.Group
                    className={styles.group}
                    onReorder={setEntries}
                    values={entries}
                >
                    {entries.length ? (
                        entries.map((entry, index) => (
                            <Reorder.Item
                                className={styles.item}
                                key={entry.path.join('/')}
                                value={entry}
                            >
                                <File
                                    file={entry}
                                    onToggleCompression={(enabled) => {
                                        // Update entry at index
                                        setEntries((current) =>
                                            current.map(
                                                (
                                                    currentEntry,
                                                    currentIndex
                                                ) => {
                                                    if (
                                                        index !== currentIndex
                                                    ) {
                                                        return currentEntry
                                                    }

                                                    const newEntry = entry
                                                    if (
                                                        newEntry.flags ===
                                                        undefined
                                                    ) {
                                                        newEntry.flags = {}
                                                    }
                                                    newEntry.flags.compressed =
                                                        enabled
                                                            ? {
                                                                  mode: 'zlib',
                                                                  level: 8,
                                                              }
                                                            : undefined
                                                    return newEntry
                                                }
                                            )
                                        )
                                    }}
                                    onRemove={() => {
                                        setEntries((current) => {
                                            const newEntries = [...current]
                                            newEntries.splice(index, 1)
                                            return newEntries
                                        })
                                    }}
                                    onUpdatePath={(path) => {
                                        // Update entry at index
                                        setEntries((current) =>
                                            current.map(
                                                (
                                                    currentEntry,
                                                    currentIndex
                                                ) => {
                                                    if (
                                                        index !== currentIndex
                                                    ) {
                                                        return currentEntry
                                                    }

                                                    const newEntry = entry
                                                    newEntry.path = path
                                                    return newEntry
                                                }
                                            )
                                        )
                                    }}
                                />
                            </Reorder.Item>
                        ))
                    ) : (
                        <Row stretch horizontalAlign={'center'}>
                            <Text extent={'small'} weight={'regular'} secondary>
                                {__('builder.fsar.no.files')}
                            </Text>
                        </Row>
                    )}
                </Reorder.Group>
                <Row
                    horizontalAlign={'center'}
                    verticalAlign={'center'}
                    gap={8}
                >
                    <Button onClick={addFile} icon={'plus'}>
                        <Text extent={'small'}>
                            {__('builder.fsar.add.file')}
                        </Text>
                    </Button>
                    <Button onClick={addDirectory} icon={'plus'}>
                        <Text extent={'small'}>
                            {__('builder.fsar.add.folder')}
                        </Text>
                    </Button>
                </Row>
            </Stack>
        </Stack>
    )
}
