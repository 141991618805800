import React from 'react'
import * as styles from './row.module.scss'
import { Alignment } from './row-stack-shared'

interface RowProps {
    verticalAlign?: Alignment
    horizontalAlign?: Alignment
    stretch?: boolean
    gap?: number
    className?: string
    children: React.ReactNode
}

const verticalAlignMap: { [key in RowProps['verticalAlign']]: string } = {
    start: styles.verticalStart,
    center: styles.verticalCenter,
    end: styles.verticalEnd,
    'space-between': '',
    'space-around': '',
}

const horizontalAlignMap: { [key in RowProps['horizontalAlign']]: string } = {
    start: styles.horizontalStart,
    center: styles.horizontalCenter,
    end: styles.horizontalEnd,
    'space-between': styles.horizontalSpaceBetween,
    'space-around': styles.horizontalSpaceAround,
}

export function Row(props: RowProps) {
    const {
        verticalAlign = 'start',
        horizontalAlign = 'start',
        stretch,
        gap,
        children,
        className,
    } = props

    const classes = [
        className,
        styles.row,
        verticalAlignMap[verticalAlign],
        horizontalAlignMap[horizontalAlign],
    ].filter(Boolean)

    if (stretch) {
        classes.push(styles.stretched)
    }

    return (
        <div className={classes.join(' ')} style={{ gap }}>
            {children}
        </div>
    )
}
