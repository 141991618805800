export type Path = string[]
export type RawPath = string
export type Separator = '/' | '\\'

export function extractPath(path: RawPath): Path {
    return path.split(/[\\/]/).filter(Boolean)
}

export function buildRawPath(path: Path, separator: Separator): RawPath {
    return path.join(separator)
}
