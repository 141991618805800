import React from 'react'
import { getLogger } from './logger'
import { useI18n } from './i18n/store'
import { Button } from './components/button'
import { Builder } from './compositions/builder'
import './index.css'

export function App() {
    const { __, switchLocale, resetLocaleOverride } = useI18n()
    const logger = getLogger('app')

    return (
        <div>
            {/*<Button icon={'wrench'} onClick={() => switchLocale('en')}>*/}
            {/*    English*/}
            {/*</Button>*/}
            {/*<Button onClick={() => switchLocale('de')}>German</Button>*/}
            {/*<Button onClick={() => resetLocaleOverride()}>Reset</Button>*/}
            <Builder />
        </div>
    )
}
