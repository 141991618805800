import React from 'react'
import * as styles from './stack.module.scss'
import { Alignment } from './row-stack-shared'

interface RowProps {
    verticalAlign?: Alignment
    horizontalAlign?: Alignment
    gap?: number
    stretch?: boolean
    children: React.ReactNode
}

const verticalAlignMap: { [key in RowProps['verticalAlign']]: string } = {
    start: styles.verticalStart,
    center: styles.verticalCenter,
    end: styles.verticalEnd,
    'space-between': styles.verticalSpaceBetween,
    'space-around': styles.verticalSpaceAround,
}

const horizontalAlignMap: { [key in RowProps['horizontalAlign']]: string } = {
    start: styles.horizontalStart,
    center: styles.horizontalCenter,
    end: styles.horizontalEnd,
    'space-between': '',
    'space-around': '',
}

export function Stack(props: RowProps) {
    const {
        verticalAlign = 'start',
        horizontalAlign = 'start',
        children,
        stretch,
        gap,
    } = props

    const classes = [
        styles.stack,
        verticalAlignMap[verticalAlign],
        horizontalAlignMap[horizontalAlign],
    ]

    if (stretch) {
        classes.push(styles.stretched)
    }

    return (
        <div className={classes.join(' ')} style={{ gap }}>
            {children}
        </div>
    )
}
