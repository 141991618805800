import { buildEntry, FsarBuilderEntry } from './builder/entry'
import { FsarHeader } from './header'
import { FsarToc } from './toc'
import { buildRawPath, Separator } from '../utils/path'
import { FSAR_HEADER_SIZE, FSAR_TOC_ENTRY_SIZE } from './constants'

export async function build(
    target: FileSystemFileHandle,
    entries: FsarBuilderEntry[],
    separator: Separator = '/'
) {
    // Steps:
    //  - Get final data and info for entries
    //  - Build header
    //  - Calculate table of content
    //  - Fill with data

    // Optional: memory constraint solution =>
    //  - WRITE INCOMPLETE TOC (SIZE KNOWN)
    //  - WRITE DATA ONE AFTER THE OTHER, KEEPING TRACK OF OFFSETS

    const header = new FsarHeader()
    const toc = new FsarToc()
    toc.separator = separator

    for (let entry of entries) {
        toc.addPlaceholderEntry(entry)
    }

    header.startOfData = FSAR_HEADER_SIZE + toc.byteLength
    header.entries = entries.length

    const writable = await target.createWritable()

    await writable.write(header.build())

    for (let entry of entries) {
        // Get final data
        const data = await buildEntry(entry)

        // Get final path
        const rawPath = buildRawPath(entry.path, separator)

        // Keep track of offset
        const startOffset = toc.getOffsetOf(entry)
        await writable.seek(header.startOfData + Number(startOffset))

        // Write data at end of file
        await writable.write(data.data)

        // Add padding at the end if it isn't the last file
        const remaining =
            data.size.stored % 0x20 === 0
                ? 0x20
                : 0x20 - (data.size.stored % 0x20)
        await writable.write(new Uint8Array(remaining).fill(0x2d))

        // Update offset and size in toc
        const tocEntryIndex = toc.entries.findIndex(
            (e) => buildRawPath(e.path, toc.separator) === rawPath
        )

        toc.entries[tocEntryIndex].size = {
            stored: BigInt(data.size.stored),
            real: BigInt(data.size.real),
        }
        toc.entries[tocEntryIndex].offset = startOffset

        // Write toc entry
        await writable.seek(
            FSAR_HEADER_SIZE + tocEntryIndex * FSAR_TOC_ENTRY_SIZE
        )
        await writable.write(toc.buildEntry(tocEntryIndex))
    }

    await writable.close()
}
