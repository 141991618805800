import create from 'zustand'
import english from './en.json'
import german from './de.json'
import { getLogger } from '../logger'
import { sprintf } from 'sprintf-js'

type I18nKey = keyof typeof english
type I18nDict = {
    [key in I18nKey]: string
}
type I18nLocale = 'en' | 'de'

/**
 * Save the user's locale in localStorage.
 * @param locale
 */
function saveUserLocale(locale: I18nLocale) {
    localStorage.setItem('locale', locale)
}

/**
 * Clears the user's locale from localStorage.
 */
function clearUserLocale() {
    localStorage.removeItem('locale')
}

function getBrowserLocale(): I18nLocale {
    // Extract locale from the browser's language string
    const locale = navigator.language.match(/^[a-z]{2}/i)?.[0]

    // Match the locale to the available locales
    switch (locale) {
        // de: German
        case 'de':
            return 'de'
        // en: English / Default
        default:
            return 'en'
    }
}

/**
 * Checks if a given locale is valid.
 * @param locale The locale to check.
 */
function isValidLocale(locale: string): boolean {
    return ['en', 'de'].includes(locale)
}

/**
 * Gets the current locale.
 */
function getCurrentLocale(): I18nLocale {
    // Get the current locale from localStorage
    const userLocale = localStorage.getItem('locale') as I18nLocale

    // Get the current language set in the browser
    const browserLocale = getBrowserLocale()

    // If there is no user locale, return the browser locale
    if (!userLocale) {
        return browserLocale
    }

    // Check if it's a valid locale
    // If not, fall back to the browser's locale
    if (!isValidLocale(userLocale)) {
        getLogger('i18n').warn(`Invalid locale: ${userLocale}`)
        clearUserLocale()
        return browserLocale
    }

    // Return the valid user locale
    return userLocale
}

/**
 * Load the dictionary for a given locale.
 * @param locale The locale of the dictionary.
 */
function loadDictionary(locale: I18nLocale) {
    switch (locale) {
        case 'de':
            return { ...english, ...german }
        default:
            return english
    }
}

/**
 * Get the current dictionary and its locale.
 */
function getCurrentLanguage(): [I18nDict, I18nLocale] {
    // Get the current locale
    let locale = getCurrentLocale()

    // Get the dictionary
    const dict = loadDictionary(locale)

    // Return the dictionary and the locale
    return [dict, locale]
}

/**
 * The I18nStore type definition.
 */
type I18nStore = {
    keys: I18nDict
    locale: I18nLocale
    __: (key: I18nKey, ...args: any[]) => string
    switchLocale: (locale: I18nLocale) => void
    resetLocaleOverride: () => void
}

/**
 * Default / initial language settings.
 */
const current = getCurrentLanguage()

export const useI18n = create<I18nStore>((setState, getState) => {
    return {
        keys: current[0],
        locale: current[1],
        __: (key, ...args) => {
            // Get the current state.
            const state = getState()

            // Check if the key is missing.
            if (!(key in state.keys)) {
                // If in development mode, let the developer know.
                if (import.meta.env.DEV) {
                    const message = `⚠️ I18n Key not found! (${key}) ⚠️`
                    getLogger('i18n').warn(message)
                    return message
                }

                return key
            }

            return sprintf(state.keys[key], ...args)
        },
        switchLocale: (locale: I18nLocale) => {
            // Check if the locale is valid.
            if (!isValidLocale(locale)) {
                getLogger('i18n').warn(`Invalid locale: ${locale}`)
                return
            }

            // Save the locale in localStorage.
            saveUserLocale(locale)

            // Load the dictionary for the locale and set it.
            setState({
                keys: loadDictionary(locale),
                locale,
            })
        },
        resetLocaleOverride: () => {
            // Get the default locale from the browser.
            const locale = getBrowserLocale()

            // Clear the locale from localStorage.
            clearUserLocale()

            // Load the dictionary for the locale and set it.
            setState({
                keys: loadDictionary(locale),
                locale,
            })
        },
    }
})
