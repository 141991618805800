import colorDict from './colors.json'
import { getLogger } from './logger'

export type Color = keyof typeof colorDict

export function getColor(key: Color) {
    if (!(key in colorDict) && import.meta.env.DEV) {
        getLogger('colors').warn(`Unknown color key: ${key}`)
    }

    return colorDict[key]
}

export const colors = {
    ...colorDict,
}
