import { FsarBuilderEntry } from './builder/entry'
import { buildRawPath, Path, Separator } from '../utils/path'
import { FSAR_TOC_ENTRY_SIZE } from './constants'

type FsarTocEntry = {
    path: Path
    compression?: 'zlib'
    offset: bigint
    size: {
        stored: bigint
        real: bigint
    }
}

export class FsarToc {
    entries: FsarTocEntry[] = []
    separator: Separator = '/'

    addPlaceholderEntry(entry: FsarBuilderEntry) {
        this.entries.push({
            path: entry.path,
            compression: entry.flags?.compressed?.mode,
            offset: 0n,
            size: {
                stored: 0n,
                real: 0n,
            },
        })
    }

    // FIXME: DO NOT REPEAT YOURSELF

    build(): ArrayBufferLike {
        const buffer = new ArrayBuffer(
            this.entries.length * FSAR_TOC_ENTRY_SIZE
        )
        const view = new DataView(buffer)

        // Format: BE
        // 0x000: sz  path => this.entries[i].path
        // 0x100: u64 stored size => this.entries[i].size.stored
        // 0x108: u64 real size => this.entries[i].size.real
        // 0x110: u64 offset => this.entries[i].offset
        // 0x118: u32 compression => this.entries[i].compression
        // 0x11C: u32 filler => 0x00

        for (let i = 0; i < this.entries.length; i++) {
            const entry = this.entries[i]

            const rawPath = buildRawPath(entry.path, this.separator)

            // Set the path
            for (let j = 0; j < Math.min(rawPath.length, 0x100); j++) {
                view.setUint8(
                    i * FSAR_TOC_ENTRY_SIZE + j,
                    rawPath.charCodeAt(j)
                )
            }

            // Set the sizes
            view.setBigUint64(
                i * FSAR_TOC_ENTRY_SIZE + 0x100,
                entry.size.stored,
                false
            )
            view.setBigUint64(
                i * FSAR_TOC_ENTRY_SIZE + 0x108,
                entry.size.real,
                false
            )

            // Set the offset
            view.setBigUint64(
                i * FSAR_TOC_ENTRY_SIZE + 0x110,
                entry.offset,
                false
            )

            // Set the compression
            switch (entry.compression) {
                case 'zlib':
                    view.setUint32(i * FSAR_TOC_ENTRY_SIZE + 0x118, 2, false)
                    break
                default:
                    view.setUint32(i * FSAR_TOC_ENTRY_SIZE + 0x118, 1, false)
            }
        }

        return buffer
    }

    buildEntry(index: number) {
        const entry = this.entries[index]
        const buffer = new ArrayBuffer(FSAR_TOC_ENTRY_SIZE)
        const view = new DataView(buffer)
        const rawPath = buildRawPath(entry.path, this.separator)

        // Format: BE
        // 0x000: sz  path => this.entries[i].path
        // 0x100: u64 real size => this.entries[i].size.real
        // 0x108: u64 stored size => this.entries[i].size.stored
        // 0x110: u64 offset => this.entries[i].offset
        // 0x118: u32 compression => this.entries[i].compression
        // 0x11C: u32 filler => 0x00

        // Set the path
        for (let j = 0; j < Math.min(rawPath.length, 0x100); j++) {
            view.setUint8(j, rawPath.charCodeAt(j))
        }

        // Set the sizes
        view.setBigUint64(0x100, entry.size.real, false)
        view.setBigUint64(0x108, entry.size.stored, false)

        // Set the offset
        view.setBigUint64(0x110, entry.offset, false)

        // Set the compression
        switch (entry.compression) {
            case 'zlib':
                view.setUint32(0x118, 2, false)
                break
            default:
                view.setUint32(0x118, 1, false)
        }

        return buffer
    }

    getOffsetOf(entry: FsarBuilderEntry) {
        const index = this.entries.findIndex((e) => e.path === entry.path)
        if (index === -1) {
            throw new Error('Entry not found')
        }

        let offset = 0n

        // Sum up stored sizes of all prior entries + padding
        for (let i = 0; i < index; i++) {
            const size = this.entries[i].size.stored
            offset += size
            offset += 0x10n - (size % 0x10n)
        }

        return offset
    }

    get byteLength() {
        return this.entries.length * FSAR_TOC_ENTRY_SIZE
    }
}
