import React from 'react'
import { Color, getColor } from '../colors'
import styles from './text.module.scss'

type TextExtent = 'header' | 'body' | 'small'
type TextWeight = 'regular' | 'bold' | 'semi-bold'

interface TextProps {
    children: React.ReactNode
    color?: Color
    extent?: TextExtent
    weight?: TextWeight
    secondary?: boolean
}

const extents: { [extent in TextExtent]: string } = {
    header: styles.extentHeader,
    body: styles.extentBody,
    small: styles.extentSmall,
}

const weights: { [weight in TextWeight]: string } = {
    regular: styles.weightRegular,
    'semi-bold': styles.weightSemiBold,
    bold: styles.weightBold,
}

export function Text(props: TextProps) {
    const { children, color, extent, weight, secondary } = props

    const classes = [
        extents[extent ?? 'body'],
        weights[weight ?? 'semi-bold'],
        secondary && styles.secondary,
    ].filter(Boolean)

    return (
        <span className={classes.join(' ')} style={{ color: getColor(color) }}>
            {children}
        </span>
    )
}

export function Header({ children, ...props }: Omit<TextProps, 'extent'>) {
    return (
        <Text {...props} extent="header">
            {children}
        </Text>
    )
}

export function SmallText({ children, ...props }: Omit<TextProps, 'extent'>) {
    return (
        <Text {...props} extent="small">
            {children}
        </Text>
    )
}

export function Description({ children, ...props }: Omit<TextProps, 'extent'>) {
    return (
        <Text {...props} extent="body" weight="regular">
            {children}
        </Text>
    )
}
