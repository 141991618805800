import { joinTemplateString } from './template'

export function ascii(...lines: string[]) {
    const joined = lines.join('')
    const buffer = new ArrayBuffer(joined.length)
    const view = new DataView(buffer)
    for (let i = 0; i < joined.length; i++) {
        view.setUint8(i, joined.charCodeAt(i))
    }
    return buffer
}

function ascii32_(text, ...keys) {
    const joined = joinTemplateString(text, ...keys)
    if (joined.length > 4) {
        throw new Error('ascii32: string is too long')
    }
    const buffer = new ArrayBuffer(4)
    const view = new DataView(buffer)
    for (let i = 0; i < joined.length; i++) {
        view.setUint8(i, joined.charCodeAt(i))
    }
    return view
}

export function ascii32(text, ...keys) {
    return ascii32_(text, ...keys).getUint32(0, false)
}

export function ascii32le(text, ...keys) {
    return ascii32_(text, ...keys).getUint32(0, true)
}
