import React from 'react'
import { Icon, IconType } from './icon'
import './button.module.scss'

interface ButtonProps {
    children: React.ReactNode
    onClick: () => void
    icon?: IconType
    type?: 'primary' | 'secondary' | 'danger'
}

export function Button({ children, onClick, icon }: ButtonProps) {
    return (
        <button onClick={onClick}>
            {icon && <Icon type={icon} size={'small'} weight={'bold'} />}
            {children}
        </button>
    )
}
