import { colors, getColor } from './colors'

type LoggerKey = 'i18n' | 'fsar' | 'colors' | 'app'
type LogLevel = 'trace' | 'info' | 'warn' | 'error' | 'fatal'

const keyColors: { [key in LoggerKey]: [string, string] } = {
    i18n: [getColor('indigo/200'), getColor('indigo/900')],
    fsar: [getColor('orange/200'), getColor('orange/900')],
    colors: [getColor('green/200'), getColor('green/900')],
    app: [getColor('red/200'), getColor('red/900')],
}

const levelColors: { [key in LogLevel]: [string, string] } = {
    trace: [getColor('gray/200'), getColor('gray/900')],
    info: [getColor('blue/200'), getColor('blue/900')],
    warn: [getColor('yellow/200'), getColor('yellow/900')],
    error: [getColor('red/200'), getColor('red/900')],
    fatal: [getColor('red/900'), getColor('red/200')],
}

class Logger {
    key: LoggerKey

    constructor(key: LoggerKey) {
        this.key = key
    }

    trace(...message: any[]) {
        this._write('trace', message)
    }

    info(...message: any[]) {
        this._write('info', message)
    }

    warn(...message: any[]) {
        this._write('warn', message)
    }

    error(...message: any[]) {
        this._write('error', message)
    }

    fatal(...message: any[]) {
        this._write('fatal', message)
    }

    private _write(level: LogLevel, message: any[]) {
        // Fixme: Flow sucks, doesn't support objects very well

        const firstIsOnlyString =
            typeof message[0] === 'string' && message.length === 1
        const raw = `%c${
            this.key
        }%c${level}%c${new Date().toLocaleTimeString()}%c\n${
            firstIsOnlyString ? message[0] : ''
        }`
        const styles = [
            `padding: 1px 4px; border-radius: 4px; margin: 2px; background: ${
                keyColors[this.key][0]
            }; color: ${keyColors[this.key][1]}`,
            `padding: 1px 4px; border-radius: 4px; margin: 2px; background: ${levelColors[level][0]}; color: ${levelColors[level][1]}`,
            'margin-left: 4px; opacity: 0.5; font-size: 0.8em; vertical-align: middle;',
            '',
        ]

        let method = console.log

        switch (level) {
            case 'trace':
                method = console.log
                break
            case 'info':
                method = console.info
                break
            case 'warn':
                method = console.warn
                break
            case 'error':
            case 'fatal':
                method = console.error
                break
        }

        if (firstIsOnlyString) {
            method(raw, ...styles)
        } else {
            method(raw, ...styles, ...message)
        }
    }
}

const loggers: Map<LoggerKey, Logger> = new Map()

export function getLogger(key: LoggerKey): Logger {
    if (!loggers.has(key)) {
        loggers.set(key, new Logger(key))
    }

    return loggers.get(key)!
}
